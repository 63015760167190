import React, { useContext } from 'react';
import { ChatContext } from './ChatContext';
import { useSelector } from "react-redux";
import ChatBubble from './ChatBubble';
import Skeleton from '@mui/material/Skeleton';
import { getRandomHeight } from './utils';

const ChatContainer: React.FC = () => {
  const { messages, currentSession, messageLoading } = useContext(ChatContext);
  const profile = useSelector((store) => store.app.profile);

  const scrollToBottom = () => {
    const chatContainer = document.querySelector('.flex-1');
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }

  React.useEffect(() => {
    scrollToBottom();
  }, [messages, currentSession]);

  return !messageLoading ? <div className="flex-1 overflow-y-auto p-4 bg-gray-100">
      {messages ? messages.map((message) => (
        <ChatBubble key={message.id} message={message} profile={profile} />
      )): null}
    </div>:
    <div className="flex-1 overflow-y-auto p-4 bg-gray-100">
      <div className="mb-4 flex justify-end">
        <div className="p-3 rounded-lg w-2/4">
          <Skeleton variant="text" width="100%" height={getRandomHeight()} />
        </div>
      </div>
      <div className="mb-4 flex justify-start">
        <div className="p-3 rounded-lg w-2/4">
          <Skeleton variant="text" width="100%" height={getRandomHeight()} />
        </div>
      </div>
      <div className="mb-4 flex justify-end">
        <div className="p-3 rounded-lg w-2/4">
          <Skeleton variant="text" width="100%" height={getRandomHeight()}  />
        </div>
      </div>
      <div className="mb-4 flex justify-start">
        <div className="p-3 rounded-lg w-2/4">
          <Skeleton variant="text" width="100%" height={getRandomHeight()}  />
        </div>
      </div>
    </div>
};

export default ChatContainer;
