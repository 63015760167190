import React, { useState,useContext } from 'react';
import { Button, TextField, IconButton } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { ChatContext } from './ChatContext';
import { groupDates } from './utils';

const SessionSelector: React.FC = () => {
  const { sessions, setCurrentSession, startNewSession , currentSession} = useContext(ChatContext);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const groupedSessions = groupDates(sessions);

  const sessionsElement = (sessions: any) => {
    return sessions.map((session) => {

      const isActive = currentSession && session.session_id === currentSession.session_id;
      return <Button 
        key={session.session_id}
        onClick={() => setCurrentSession(session)}
        className={`text-sm	text-gray-600 rounded cursor-pointer hover:bg-gray-300 p-2 cursor-pointer normal-case	text-left	${isActive ? 'bg-gray-300' : ''}`}
      >
        {session.title}
      </Button>
    }
    )
  };

  return (
    <div
      className={`${
        sidebarOpen ? "w-1/4" : "w-0"
      } transition-all fixed lg:static lg:translate-x-0 text-gray-200 p-4 h-full`}
    >
      <div className="absolute top-4 right-4 z-10">
        <IconButton onClick={toggleSidebar} classes="bg-gray-600">
          {sidebarOpen ? (
            <KeyboardDoubleArrowLeftIcon />
          ) : (
            <KeyboardDoubleArrowRightIcon />
          )}
        </IconButton>
        {!sidebarOpen ? null : (
          <IconButton onClick={startNewSession} classes="bg-gray-600">
            <AddBoxIcon />
          </IconButton>
        )}
      </div>
      <div className={`${sidebarOpen ? "" : "hidden"} space-y-4`}>
        <div className="space-y-2">
          {sessions.length === 0 ? (
            <div key="thisWeek">
              <h2 className="text-sm text-gray-500">No sessions</h2>
            </div>
          ) : null}
          {groupedSessions.thisWeek.length > 0 ? (
            <div key="thisWeek">
              <h2 className="text-sm text-gray-500">This week</h2>
              <div className="space-y-2">
                {sessionsElement(groupedSessions.thisWeek)}
              </div>
            </div>
          ) : null}
          {groupedSessions.lastWeek.length > 0 ? (
            <div key="lastWeek">
              <h2 className="text-sm text-gray-500">Last week</h2>
              <div className="space-y-2">
                {sessionsElement(groupedSessions.lastWeek)}
              </div>
            </div>
          ) : null}
          {groupedSessions.lastMonth.length > 0 ? (
            <div key="older">
              <h2 className="text-sm text-gray-500">last Month</h2>
              <div className="space-y-2">
                {sessionsElement(groupedSessions.lastMonth)}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SessionSelector;
