interface WebSocketMessage {
    output: string;
  }
  
  const defaultListenerId = 'default';

  class WebSocketClient {
    private socket: WebSocket;
    private listeners: Record<string, ((payload: any) => void)[]> = {};
  
    constructor(url: string) {
      this.socket = new WebSocket(url);
    }
  
    connect() {
      this.socket.onopen = () => {
        console.log('Connected to WebSocket');
      };
  
      this.socket.onmessage = (messageEvent) => {
        console.log('Received message:', messageEvent.data);
        const message: WebSocketMessage = JSON.parse(messageEvent.data);
        const callbacks = this.listeners[defaultListenerId];
        if (callbacks) {
          callbacks.forEach(callback => callback(message));
        }
      };
  
      this.socket.onerror = (ev) => {
        console.log('WebSocket error:', ev);
      }
      this.socket.onclose = (ev) => {
        console.log('WebSocket connection closed:', ev);
      };
    }
  
    on(event: string = defaultListenerId, callback: (payload: any) => void) {
      if (!this.listeners[event]) {
        this.listeners[event] = [];
      }
      this.listeners[event].push(callback);
    }
  
    send(payload: any) {
      this.socket.send(JSON.stringify(payload));
      return false;
    }
  }
  
  export default WebSocketClient;
  