import React from "react";
import InnerHTML from "dangerously-set-html-content";
import DOMPurify from 'dompurify';
import { marked } from 'marked';
import { containsMarkdown, getRandomChar, getHumanReadableDate } from "./utils";
import { Message } from "./types";

interface ChatBubbleProps {
  message: Message;
  profile: any;
}

const ChatBubble: React.FC<ChatBubbleProps> = ({ message , profile }) => {
  const isUser = message.sender === "human";
  const chartId = `chart_div_${getRandomChar()}`;
  const cleanText = message.text.replaceAll("chart_div", chartId);

  const renderMessageContent = (messageText: string) => {
    // Convert markdown to HTML
    if (!messageText.includes("static.com/chart")) {
      const rawHtml = marked(messageText);

      // Sanitize HTML to avoid XSS attacks
      const cleanHtml = DOMPurify.sanitize(rawHtml);
      return <div dangerouslySetInnerHTML={{ __html: cleanHtml }} />;

    }
    else {
      const cleanText = messageText.replaceAll("```html","").replaceAll("```","");
      // Render the sanitized HTML safely using dangerouslySetInnerHTML
      return <InnerHTML html={cleanText}  allowRerender={true}/>
    }
  };

  return (
    <div className={`mb-4 flex ${isUser ? "justify-end" : "justify-start"}`}>
      <div
        className={`${
          isUser ? "bg-primary text-white justify-end" : "bg-gray-300 text-black justify-start"
        } p-3 rounded-lg w-2/4`}
      >
        {renderMessageContent(cleanText)}
        <div className={`flex text-xs italic text-gray-750 mt-1 ${isUser ? "justify-end" : "justify-start"}`}>
                {getHumanReadableDate(message.timestamp)}
              </div>
      </div>
    </div>
  );
};

export default ChatBubble;
