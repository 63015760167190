import axios from 'axios';
import apiClient from './apiClient';
import { title } from 'process';

// Define types for the API responses
interface Session {
  session_id: string;
  title: string;
  created: Date;
}

interface SessionResponse {
  Session: string[];
}

interface SessionCreated {
  session_id: string;
}

interface Message {
  id: string;
  session_id: string;
  sender: 'human' | 'ai';
  text: string;
}

interface MessageResponse {
  id: string;
  session_id: string;
  type: 'human' | 'ai';
  content: string;
  created_at: string;
}

// Set base URL
const token = localStorage.getItem("datagramToken");

// Fetch all user sessions
export const getSessions = async (): Promise<Session[]> => {
  try {
    const response = await apiClient.post<SessionResponse>(`/sessions`, {
      token: token
    });
    return response.data.map((session) => {
      return { session_id: session.session_id, title: session.title , created: Date.parse(session.created) };
    });
  } catch (error) {
    console.error('Error fetching sessions:', error);
    return [];
  }
};

// Create a new chat session
export const createChatSession = async (): Promise<Session | null> => {
  try {
    const response = await apiClient.post<SessionCreated>(`/create_chat`,{
      "token": token
    });
    return { session_id: response.data.session_id, title: 'New Session' };
  } catch (error) {
    console.error('Error creating chat session:', error);
    return null;
  }
};

// Fetch all messages for a specific session
export const getSessionMessages = async (sessionId: string): Promise<Message[]> => {
  try {
    const response = await apiClient.post<MessageResponse[]>(`/session_messages`,
    { session_id: sessionId, "token": token });
    const data =  response.data.reverse();
    if (data.length > 0) {
      return data.map((message) => {
        return {
          id: message.id,
          session_id: message.session_id,
          sender: message.type,
          text: message.content,
          timestamp: Date.parse(message.created_at)
        } as Message;
      });
    }
    return [];
  } catch (error) {
    console.error('Error fetching session messages:', error);
    return [];
  }
};
